import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    subCategory: [],
    loading: false,
    error: null,
};

const subCategorySlice = createSlice({
    name: "subCategory",
    initialState,
    reducers: {
        fetchSubsCategoryRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchSubsCategorySuccess: (state, action) => {
            state.loading = false;
            state.subCategory = action.payload;
        },
        fetchSubsCategoryFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchSubsCategoryRequest,
    fetchSubsCategorySuccess,
    fetchSubsCategoryFailure,
} = subCategorySlice.actions;
export default subCategorySlice.reducer;
