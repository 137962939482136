import { addItemToCart, clearCart, remove } from "../reducers/cartSlice";

export const addToCart = (product) => (dispatch, getState) => {
  dispatch(addItemToCart({ product }));
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch(remove(id));
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeCart = () => (dispatch) => {
  dispatch(clearCart());
  localStorage.removeItem("cartItems");
};
