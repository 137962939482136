import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Route, Switch, useHistory } from "react-router-dom";
import Spinner from "../components/Spinner";
import "react-toastify/dist/ReactToastify.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.esm.js";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { signout } from "../store/actions/authActions";
import { fetchSubCategory } from "../store/actions/subCategoryAction";
import { fetchSubs } from "../store/actions/subActions";

const HomePage = React.lazy(() => import("./home-route"));
const AdminPages = React.lazy(() => import("./admin-route"));

const AppRoot = React.memo(() => {
  injectStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [role, setRole] = useState(localStorage.getItem("role"));

  useEffect(() => {
    dispatch(fetchSubCategory());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSubs());
  }, [dispatch]);

  const handleUserActivity = async (isPrompted) => {
    const email = localStorage.getItem("email");
    if (role === "admin" && email && isPrompted) {
      try {
        const response = await Axios.post(
          `${process.env.REACT_APP_API}/staff/signout`,
          { email }
        );
        dispatch(signout(history, email));
        toast.success(response.data.message);
      } catch (error) {
        toast.error("Error while logging out");
      }
    }
  };

  const onIdle = () => {
    console.log("User is idle");
  };

  const { getRemainingTime, isPrompted } = useIdleTimer({
    timeout: 1000 * 60 * 60, // 1 hour
    promptBeforeIdle: 1000 * 30, // 30 seconds before idle
    onIdle: () => onIdle(),
    onPrompt: () => handleUserActivity(true),
    onAction: () => handleUserActivity(isPrompted()),
    debounce: 500,
  });

  useEffect(() => {
    const unsubscribe = history.listen(() => {
      setRole(localStorage.getItem("role"));
    });
    return () => {
      unsubscribe();
    };
  }, [history]);

  return (
    <React.Suspense fallback={<Spinner />}>
      <ToastContainer />
      <Switch>
        <Route path="/admin" component={AdminPages} />
        <Route path="/" component={HomePage} />
      </Switch>
    </React.Suspense>
  );
});

export default AppRoot;
