import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subs: [],
  loading: false,
  error: null,
};

const subsSlice = createSlice({
  name: "subs",
  initialState,
  reducers: {
    fetchSubsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchSubsSuccess: (state, action) => {
      state.loading = false;
      state.subs = action.payload;
    },
    fetchSubsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchSubsRequest,
  fetchSubsSuccess,
  fetchSubsFailure,
} = subsSlice.actions;
export default subsSlice.reducer;
