import {
    fetchSubsCategoryFailure,
    fetchSubsCategoryRequest,
    fetchSubsCategorySuccess,
} from "../reducers/subCategorySlice";
import { getSubCategories } from "./../../functions/subCategory";

export const fetchSubCategory = () => async (dispatch) => {
    dispatch(fetchSubsCategoryRequest());
    try {
        const response = await getSubCategories();
        const subCategory = response.data;
        dispatch(fetchSubsCategorySuccess(subCategory));
    } catch (error) {
        dispatch(fetchSubsCategoryFailure(error));
    }
};
