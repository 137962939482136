import {
  fetchSubsFailure,
  fetchSubsRequest,
  fetchSubsSuccess,
} from "../reducers/subsSlice";
import { getSubs } from "./../../functions/sub";

export const fetchSubs = () => async (dispatch) => {

  dispatch(fetchSubsRequest());

  try {
    const response = await getSubs();
    const subs = response.data;
    dispatch(fetchSubsSuccess(subs));
  } catch (error) {
    dispatch(fetchSubsFailure(error));
  }
};
