import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: JSON.parse(localStorage.getItem("cartItems") || "[]"),
  totalQuantities: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const { product } = action.payload;
      const check = state.cartItems.find((pr) => pr.id === product.id);
      if (check) {
        return state;
      } else {
        const Tquantities = state.totalQuantities + product.quantity;
        state.cartItems.push(product);
        state.totalQuantities = Tquantities;
      }
    },
    increment: (state, action) => {
      const findPro = state.cartItems.find(
        (product) => product.id === action.payload
      );
      if (findPro) {
        findPro.quantity += 1;
        state.totalQuantities += 1;
      }
    },
    decrement: (state, action) => {
      const findPro = state.cartItems.find(
        (product) => product.id === action.payload
      );
      if (findPro && findPro.quantity > 1) {
        findPro.quantity -= 1;
        state.totalQuantities -= 1;
      }
    },
    remove: (state, action) => {
      const index = state.cartItems.findIndex(
        (product) => product.id === action.payload
      );
      if (index !== -1) {
        state.totalQuantities -= state.cartItems[index].quantity;
        state.cartItems.splice(index, 1);
      }
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.totalQuantities = 0;
    },
  },
});

export const {
  addItemToCart,
  increment,
  decrement,
  remove,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
