import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPhoneCase: false,
};

const phoneCaseSlice = createSlice({
  name: "phoneCase",
  initialState,
  reducers: {
    setPhoneCaseStatus: (state, action) => {
      state.isPhoneCase = action.payload;
    },
  },
});

export const { setPhoneCaseStatus } = phoneCaseSlice.actions;
export default phoneCaseSlice.reducer;
