import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  phonenumber: null,
  isAdmin: false,
  authenticate: false,
  authenticating: false,
  loading: false,
  error: null,
  message: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRequest(state) {
      state.authenticating = true;
    },
    loginSuccess(state, action) {
      const { token, phonenumber, role } = action.payload;
      state.phonenumber = phonenumber;
      state.isAdmin = role === "admin";
      state.token = token;
      state.authenticate = true;
      state.authenticating = false;
    },
    logoutRequest(state) {
      state.loading = true;
    },
    logoutSuccess(state) {
      return initialState;
    },
    logoutFailure(state, action) {
      state.error = action.payload.error;
      state.loading = false;
    },
  },
});

export const {
  loginRequest,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
  logoutFailure,
} = authSlice.actions;

export default authSlice.reducer;
