import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ugxToUsdRate: null,
  ugxToKesRate: null,
  ugxToRwfRate: null,
  activeCurrency: "UGX",
};

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {
    getCurrencies: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    changeActiveCurrency: (state, action) => {
      return {
        ...state,
        activeCurrency: action.payload,
      };
    },
  },
});

export const { getCurrencies, changeActiveCurrency } = currencySlice.actions;
export default currencySlice.reducer;
