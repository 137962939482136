import axios from "axios";
import { addToCart } from "./cartActions";
import {
  loginRequest,
  loginSuccess,
  logoutRequest,
  logoutSuccess,
} from "../reducers/authSlice";
import { toast } from "react-toastify";

export const loginUser = (user, history, close, selectedProduct = null) => {
  return async (dispatch) => {
    dispatch(loginRequest());

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API}/otp/verifyOTP`,
        { ...user }
      );

      if (res.status === 200) {
        const token = res.data.token;
        const phonenumber = res.data.profile.phonenumber;
        const role = res.data.profile.role;

        localStorage.setItem("token", token);
        localStorage.setItem("phonenumber", phonenumber);
        localStorage.setItem("role", role);

        dispatch(loginSuccess({ token, phonenumber, role }));

        if (selectedProduct) {
          dispatch(addToCart(selectedProduct));
        }

        history.push("/checkout");
        close();
      } else if (res.status === 400) {
        dispatch(logoutSuccess());
      }
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(
        "Incorrect OTP Entered! Please check your OTP and try again."
      );
      dispatch(logoutSuccess());
    }
  };
};

export const login = (user, history, close) => {
  return async (dispatch) => {
    dispatch(loginRequest());
    const res = await axios.post(`${process.env.REACT_APP_API}/otp/verifyOTP`, {
      ...user,
    });
    if (res.status === 200) {
      const token = res.data.token;
      const phonenumber = res.data.profile.phonenumber;
      const role = res.data.profile.role;

      localStorage.setItem("token", token);
      localStorage.setItem("phonenumber", phonenumber);
      localStorage.setItem("role", role);

      dispatch(loginSuccess({ token, phonenumber, role }));

      history.push("/");
      close();
    } else {
      if (res.status === 400) {
        dispatch(logoutSuccess());
      }
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    if (token) {
      const phonenumber = localStorage.getItem("phonenumber");
      const role = localStorage.getItem("role");
      dispatch(loginSuccess({ token, phonenumber, role }));
    } else {
      dispatch(logoutSuccess());
    }
  };
};

export const signout = (history) => {
  return async (dispatch) => {
    dispatch(logoutRequest());
    localStorage.clear();
    dispatch(logoutSuccess());
    history.push("/");
  };
};
