import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isHorizontalLayout: true,
};

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setHorizontalLayout: (state) => {
      state.isHorizontalLayout = true;
    },
    setVerticalLayout: (state) => {
      state.isHorizontalLayout = false;
    },
  },
});

export const { setHorizontalLayout, setVerticalLayout } = layoutSlice.actions;
export default layoutSlice.reducer;
