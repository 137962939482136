import axios from "axios";

export const getSubCategories = async () =>
    await axios.get(`${process.env.REACT_APP_API}/sub-category`);

export const getSubCategory = async (slug) =>
    await axios.get(`${process.env.REACT_APP_API}/sub-category/${slug}`);

export const removeSubCategory = async (slug) =>
    await axios.delete(`${process.env.REACT_APP_API}/sub-category/${slug}`);

export const updateSubCategory = async (slug, SubCategory, authtoken) =>
    await axios.put(`${process.env.REACT_APP_API}/sub-category/${slug}`, SubCategory, {
        headers: {
            authtoken,
        },
    });

export const createSubCategory = async (SubCategory) =>
    await axios.post(`${process.env.REACT_APP_API}/sub-category`, SubCategory);

export const getSubCategoryBrand = async (_id) =>
    await axios.get(`${process.env.REACT_APP_API}/sub-category/brand/${_id}`);
