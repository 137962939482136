import React from "react";

const LoadSpinner = () => (
  <div className="d-flex justify-content-center pt-2 mt-2 mb-2">
    <div className="loader-demo-box">
      <div className="jumping-dots-loader">
        <span /> <span /> <span />
      </div>
    </div>
  </div>
);

export default LoadSpinner;
